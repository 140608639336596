<template lang="pug">
  .period-settings
    table
      thead
        tr
          th.header-item.sticky-col.priod-group-name
            span {{ $t("plan_management.table.columns.status") }}
          th.header-item.sticky-col.period-start-date
            span {{ $t("plan_management.table.columns.ota_name") }}
          th.header-item.sticky-col.period-end-date
            span {{ $t("plan_management.table.columns.plan_name") }}
          th.header-item.sticky-col.actions
      tbody
        template(v-for="periodSettingItem in [...periodSettingItemsAdded, ...periodSettingItems]")
          tr(:class="{ 'added': periodSettingItem._addedAt }")
            td.name-cell(:rowspan="periodSettingItem.periods.length + 2")
              AppInput.name-input(
                type="text"
                :value="periodSettingItem.name"
                @input=""
              )
          template(v-for="(period, index) in periodSettingItem.periods")
            tr(:class="{ 'added': periodSettingItem._addedAt || period._addedAt }")
              td
                AppDatepicker.date-input(:value="period.start_date")
              td
                AppDatepicker.date-input(:value="period.end_date")
          tr.add-new-row(:class="{ 'added': periodSettingItem._addedAt }")
            td
              AppIconButton.add-new(
                icon="plus-circle"
                title="plan_management.actions.add_new",
                @click="$emit('add-new-period', periodSettingItem)"
              )
            td
            td
</template>

<script>
  export default {
    components: {
      AppInput: () => import("@/components/elements/AppInput"),
      AppDatepicker: () => import("@/components/elements/AppDatepicker"),
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other")
    },

    props: {
      periodSettingItems: {
        type: Array,
        default: () => new Array()
      },
      periodSettingItemsAdded: {
        type: Array,
        default: () => new Array()
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/common.sass"
  @import "@/assets/styles/tables/custom.sass"
  @import "@/assets/styles/matchings.sass"

  .period-settings
    +listing-container(10px)
    position: relative
    overflow: auto
    margin-top: 20px

    table
      width: 100%
      table-layout: fixed
      overflow-wrap: break-word

      thead
        th
          +custom-table-th

          &.sticky-col
            +sticky-col
            top: 0
            z-index: 10

          &.header-item
            align-items: center
            span
              width: 260px

          &.priod-group-name
            width: 48%

          &.period-start-date,
          &.period-end-date
            width: 25%

          &.actions
            width: 2%
      tbody
        tr
          &.added
            background-color: $default-purple-light

          &.add-new-row
            border-bottom: 1px solid $default-gray-light

          td
            padding: 5px 10px

            &.name-cell
              vertical-align: top

            .name-input,
            .date-input
              width: 100%

            .name-input
              padding-left: 10px
              font-size: 0.8rem

            &.actions
              padding: 0
              color: $default-purple

              svg
                cursor: pointer
            .add-new
              background-color: $default-purple
              ::v-deep
                svg
                  path
                    fill: $default-white
                .title
                  color: $default-white

            ::v-deep
              input
                border-color: $default-purple !important
                height: 32px !important
</style>
